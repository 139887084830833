import React from "react"

import SEO from "../components/Seo"
import Layout from "../components/Layout"

import Contact from "../components/contact"

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="تماس با من"
        description="در صورت داشتن هرگونه سوال، مشاره، برنامه ریزی و یا ارائه پروپزال از طریق اطلاعات تماس این صفحه با بنده در ارتباط باشید"
      />

      <Contact />
    </Layout>
  )
}

export default ContactPage