import React from 'react';

class Contact extends React.Component {
    render() {
        return (
            <section className="contact-wrapper wrapper">
                <div className="container-fluid">
                    <div className="wrapper-title">
                        <span className="heading-meta">مشخصات تماس</span>
                        <h1>تماس با من</h1>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="contact-box">
                                <i className="fas fa-phone"></i>
                                <strong>موبایل:</strong>
                                <a href="tel:+989397967117" rel="noreferrer noopener nofollow" className="ltr-text">0939 796 71 17</a>
                            </div>
                            <div className="contact-box">
                                <i className="fas fa-envelope"></i>
                                <strong>ایمیل:</strong>
                                <a href="mailto:navid.behroozimajd78@outlook.com" className="ltr-text">navid.behroozimajd78@outlook.com</a>
                            </div>
                            <div className="contact-box">
                                <i className="fab fa-instagram"></i>
                                <strong>اینستاگرام:</strong>
                                <a href="https://www.instagram.com/navid_behroozi_" target="_blank" rel="nofollow external noopener noreferrer" className="ltr-text">@navid_behroozi_</a>
                            </div>
                            <div className="contact-box">
                                <i className="fas fa-location-arrow"></i>
                                <strong>تلگرام:</strong>
                                <a href="https://t.me/nbehroozi" target="_blank" rel="nofollow external noopener noreferrer" className="ltr-text">nbehroozi</a>
                            </div>
                            <div className="contact-box">
                                <i className="fab fa-linkedin"></i>
                                <strong>لینکدین:</strong>
                                <a href="https://www.linkedin.com/in/navid-behroozi-9986ab174/" target="_blank" rel="nofollow external noopener noreferrer" className="ltr-text">navid behroozi</a>
                            </div>
                            <div className="contact-box">
                                <i className="fab fa-twitter"></i>
                                <strong>توییتر:</strong>
                                <a href="https://twitter.com/BehrooziNavid" target="_blank" rel="nofollow external noopener noreferrer" className="ltr-text">BehrooziNavid</a>
                            </div>
                            <div className="contact-box">
                                <i className="fas fa-map-marker-alt"></i>
                                <strong>محل زندگی:</strong>
                                کرج
                            </div>
                            <div className="contact-box">
                                <i className="fas fa-birthday-cake"></i>
                                <strong>متولد:</strong>
                                24 اسفند 1378
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contact;